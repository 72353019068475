define(['app'], function(app) {

  const externalSku = function() {
    const component = {};

    const _config = {
      channels: {
        update: 'externalSku/update'
      },
      selectors: {
        externalSku: '[data-component=externalSku]',
      }
    };

    const _init = (element, userDriven) => {
      component.element = element;
      if (!userDriven) {
        app.subscribe(component.config.channels.update, component.update);
      }
      return component;
    };

    const _update = (productId) => {
      let requestUrl = "/" + productId + ".externalSku"
      app.ajax.get({
        url: requestUrl,
        success: component.successHandler,
        error: component.errorHandler,
      });
    }

    const _successHandler = (response) => {
      const parent = component.element.parentNode;
      parent.innerHTML = response;
      const element = parent.querySelector(component.config.selectors.externalSku);
      component.init(element, true);
    };


    const _errorHandler = () => {
      console.error('ERROR: Could not retrieve new product external sku');
    };

    component.config = _config;
    component.init = _init;
    component.update = _update;
    component.successHandler = _successHandler;
    component.errorHandler = _errorHandler;
    return component;
  };

  return externalSku;
});
